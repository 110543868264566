<template>
  <ValidationObserver ref="guestForm" v-slot="{ handleSubmit, invalid }">
    <div class="flex justify-between mb-10">
      <h4 class="text-3xl sm:text-5xl font-bold w-2/3 leading-tight">
        We want to get to know you better
      </h4>
      <!-- Step counter -->
      <StepsCounter v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
    </div>

    <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
      Let’s fill out the rest of your information. This helps the Show make a decision about your booking.
    </div>
    <form method="post" @submit.prevent="handleSubmit(saveGuest)">
      <div class="mt-12 grid grid-cols-1 row-gap-6">
        <div>
          <label class="font-medium text-black">Your name</label>
          <ValidationProvider rules="required" name="Name" vid="name" v-slot="{ errors }">
            <div class="mt-2">
              <input type="text" class="form-input text-black text-lg bg-gray-50 w-full px-5 py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl" v-model="guestForm.name">
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div>
          <label class="font-medium text-black">Short Description</label>
          <ValidationProvider rules="required" name="Short Description" vid="title" v-slot="{ errors }">
            <div class="mt-2">
              <input type="text" class="form-input text-black text-lg bg-gray-50 w-full px-5 py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl" v-model="guestForm.title">
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div>
          <label class="font-medium text-black">Your Bio</label>
          <ValidationProvider name="Your Bio" vid="bio" v-slot="{ errors }">
            <div class="mt-2">
              <textarea v-model="guestForm.bio"
                      class="w-full bg-gray-50 rounded-xl resize-none p-5 text-black text-lg focus:border focus:border-pink-500 focus:shadow-3xl focus:bg-white"
                      :class="{'border-red-500': errors.length}"/>
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div>
          <label class="font-medium text-black mb-2">Guest Image</label>
          <ValidationProvider name="Image" vid="imageUrl" v-slot="{ errors }">
            <div class="mt-2 flex-1 flex justify-between">
              <MediaUploadSingle
                entity="guest"
                :working="loading"
                :modelId="guestForm.id"
                :imageUrl="guestForm.imageUrl"
                :imageUuid="guestForm.imageUuid"
                :error="errors[0]"
                @uploaded="uploaded"
                @deleted="deleted"
              />
            </div>
          </ValidationProvider>
        </div>

        <div>
          <label class="font-medium text-black">Your expertise</label>
          <TagSelect @selected="onTagSelected" :preselected="guest ? guest.tags : []" />
        </div>

        <div v-if="categories.length">
          <label class="font-medium text-black">Categories</label>
          <ValidationProvider rules="required" name="Categories" vid="categories" v-slot="{ errors }">
            <multiselect
              v-model="guestForm.categories"
              placeholder="Pick at least one category"
              label="name"
              track-by="name"
              :options="categories"
              :multiple="true"
              :max="2"
              class="new-multiselect"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                  <span v-if="option.parent_id" class="font-light">{{option.parent_name}}: </span><span class="font-bold">{{option.name}}</span>
                  <i class="multiselect__tag-icon" @click="remove(option)"></i>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="text-xs"><span v-if="props.option.parent_id">- </span><span class="" :class="{'font-semibold': props.option.parent_id == null}">{{ props.option.name }}</span></div>
              </template>
            </multiselect>
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <!-- Links -->
        <!-- <Links :links="guestForm.links" /> -->

        <GuestItems :items="guestForm.experience" empty="No experience added." add="Add experience">
          I have experience in...
        </GuestItems>

        <GuestItems :items="guestForm.topics" empty="No topics added." add="Add topic">
          Topics I like discussing...
        </GuestItems>

        <GuestItems :items="guestForm.questions" empty="No questions added." add="Add question">
          Questions to ask me...
        </GuestItems>

      </div>

      <div v-if="invalid" class="mt-12 ">
        <p class="text-red-500">Please fix the form errors.</p>
      </div>

      <div class="mt-12 flex justify-center">
        <button type="submit" class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16" :class="{'opacity-50': loading || invalid}">
          <span v-if="loading">
            <loading-icon class="h-5 w-5"/>
          </span>
          <span v-else>Continue</span>
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import api from '@/api'
  import StepsCounter from '@/components/StepsCounter'
  import Multiselect from 'vue-multiselect'
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';
  // import Links from "@/components/register/Links"
  import GuestItems from "@/components/register/GuestItems"
  import TagSelect from '@/components/shared/TagSelect';

  export default {
    name: "GuestForm",

    components: { StepsCounter, Multiselect, MediaUploadSingle, TagSelect, GuestItems},

    data() {
      return {
        loading: false,
        tags: [],
        categories: [],
        guestForm: {
          tags: [],
          categories: [],
          experience: [],
          questions: [],
          topics: [],
          links: {
            social_media: {
              linkedin: '',
              twitter: '',
              instagram: '',
              youtube: '',
              facebook: '',
              wikipedia: '',
              website: '',
              tiktok: '',
            },
            podcasts: [],
            videos: [],
            articles: [],
          },
        }
      }
    },

    watch: {
      guest(guest) {
        this.guestForm = guest
      },
    },

    computed: {
      steps() {
        return this.$store.getters['showBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.showBooking.currentStep
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      guest() {
        return this.user ? this.user.guest : null
      },
    },

    methods: {
      onTagSelected(tags) {
        if (tags) {
          this.guestForm.tags = tags
        }
      },

      async saveGuest() {
        this.loading = true

        try {
          if (this.guestForm.id) {
            await this.$store.dispatch('guest/updateGuest', this.guestForm)
            this.$store.dispatch('auth/getUser')
            this.setBookingGuest(this.guestForm)
            this.nextStep()
            return
          }

          await this.$store.dispatch('guest/create', this.guestForm)
          this.setBookingGuest(this.$store.state.guests.guest)

          // refresh user
          await this.$store.dispatch('auth/getUser')

          this.nextStep()
        } catch(error) {
          this.loading = false

          if (error.response) {
            this.$refs.guestForm.setErrors(error.response.data.errors)
            this.$toast.error('Error. Fix form errors.')
            return
          }

          this.$toast.error('Error. The guest details were not saved')
        }
      },

      setBookingGuest(guest) {
        this.$store.commit('showBooking/setGuest', guest)
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({name: nextStepRoute})
      },

      uploaded(url) {
        this.guestForm.imageUrl = url
        this.update()
      },

      deleted() {
        this.guestForm.imageUrl = null
      },
    },

    created() {
      api.get(`/categories?exclude=subcategories`).then(({ data }) => {
        if (data.success) {
          this.categories = data.data
        }
      })
    },

    mounted() {
      if (this.guest) {
        this.guestForm = this.guest
      }
    },
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .new-multiselect {
    background-color: '#f5f5f5' !important;
  }
  .new-multiselect .multiselect__select {
    padding: 16px 8px 4px 8px;
  }
  .new-multiselect .multiselect__tags {
    /*border-radius: 999px;*/
    padding: 13px 40px 0 8px;
  }
</style>
