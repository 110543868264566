<template>
  <div>
    <div class="register-label">
      <slot />
    </div>

    <p v-if="items.length == 0" class="h-12 mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
      {{ empty }}
    </p>
    <div class="flex items-end mb-4" v-for="(item, index) in items" :key="`item-${index}`">
      <div class="w-full mr-3">
        <input type="text" class="guestio-form-input" placeholder="Type or paste text..." v-model="items[index]">
      </div>
      <div class="w-1/12 flex justify-end my-auto">
        <button class="rounded-full bg-gray-50 flex justify items-center p-2 hover:bg-softGray focus:bg-softGray" @click.prevent="removeItem(index)">
          <CrossIcon :classes="'w-6'"/>
        </button>
      </div>
    </div>
    <button type="button" class="text-pink-500 underline focus:shadow-outline rounded" @click.prevent="addItem">{{ add }}  +</button>
  </div>
</template>

<script>
  import CrossIcon from "@/components/svgs/CrossIcon";
  export default {
    props: {
      items: Array,

      empty: {
        type: String,
        default: 'No items added.',
      },

      add: {
        type: String,
        default: 'Add experience',
      }
    },

    components: {CrossIcon},

    methods: {
      addItem() {
        this.items.push('')
      },
      removeItem(index) {
        this.items.splice(index, 1)
      },
    }
  }
</script>
