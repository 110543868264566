<template>
  <div class="min-h-full flex flex-col">
    <GuestForm v-if="user && user.id" />
    <div v-else class="max-w-md mx-auto flex items-center justify-center min-h-screen">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
  </div>
</template>

<script>
  import GuestForm from '@/components/Book/show/GuestForm'

  export default {
    components: {
      GuestForm,
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      steps() {
        return this.$store.getters['showBooking/enabledSteps']
      },
    },

    methods: {
      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'profile')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('showBooking/setCurrentStep', step + 1)
      }
    },

    mounted() {
      this.determineStepNumber()
    }
  }
</script>
